// extracted by mini-css-extract-plugin
export var alignLeft = "w_qh d_bG";
export var alignCenter = "w_bP d_bD";
export var alignRight = "w_qj d_bH";
export var textAlignLeft = "w_tv";
export var textAlignCenter = "w_tw";
export var textAlignRight = "w_tx";
export var hoursInnerWrapperAlt = "w_ty d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "w_tz d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "w_tB d_dw";
export var titleMargin = "w_tC d_cw";
export var hoursInnerInnerWrapper = "w_tD d_cz";