// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_tm d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_tn d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_tp d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_tq d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_tr d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_ts d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_tt d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_rg q_rg";
export var heroExceptionNormal = "v_rh q_rh";
export var heroExceptionLarge = "v_rj q_rj";
export var Title1Small = "v_qX q_qX q_qr q_qs";
export var Title1Normal = "v_qY q_qY q_qr q_qt";
export var Title1Large = "v_qZ q_qZ q_qr q_qv";
export var BodySmall = "v_q6 q_q6 q_qr q_qL";
export var BodyNormal = "v_q7 q_q7 q_qr q_qM";
export var BodyLarge = "v_q8 q_q8 q_qr q_qN";