// extracted by mini-css-extract-plugin
export var alignLeft = "n_qh d_fp d_bG d_dv";
export var alignCenter = "n_bP d_fq d_bD d_dw";
export var alignRight = "n_qj d_fr d_bH d_dx";
export var FAQMainHeader = "n_kt d_kt d_w d_by";
export var FAQComponentHeader = "n_kz d_kz d_bx";
export var FAQComponentParagraph = "n_kv d_kv d_cD";
export var FAQComponentsWrapper = "n_kr d_kr d_cn d_cL d_cR d_c2 d_cX d_dB";
export var FAQComponentsWrapperClean = "n_ks d_ks d_cn d_cL d_cR d_c0 d_dB";
export var accordion__body = "n_kB d_kB d_by";
export var accordion__bodyHidden = "n_kD d_kD d_bw d_dl";
export var FAQDivider = "n_kq d_kq";
export var accordionWrapper = "n_qk d_cg";
export var accordion__item = "n_ql d_cL";
export var accordion_arrow = "n_kH d_kH";
export var accordion_arrow_open = "n_kK d_kK";
export var accordion_arrow_2 = "n_kM d_kM";
export var accordion_arrow_2_open = "n_kP d_kP";
export var Subtitle2Small = "n_qm q_qm q_qr q_qH";
export var Subtitle2Normal = "n_qn q_qn q_qr q_qJ";
export var Subtitle2Large = "n_qp q_qp q_qr q_qK";