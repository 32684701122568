// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_sW d_fp d_bG d_dv";
export var alignLeft = "t_qh d_fp d_bG d_dv";
export var alignDiscCenter = "t_sX d_fq d_bD d_dw";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignDiscRight = "t_sY d_fr d_bH d_dx";
export var alignRight = "t_qj d_fr d_bH d_dx";
export var footerContainer = "t_sZ d_dW d_bW";
export var footerContainerFull = "t_s0 d_dT d_bW";
export var footerHeader = "t_kf d_kf";
export var footerTextWrapper = "t_s1 d_w";
export var footerDisclaimerWrapper = "t_km d_km d_ck";
export var badgeWrapper = "t_s2 d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "t_s3 d_bz d_bJ d_bN d_bL";
export var wide = "t_s4 d_cy";
export var right = "t_s5 d_bK";
export var line = "t_fk d_fl d_cv";
export var badgeDisclaimer = "t_s6 d_bC d_bP d_bJ";
export var badgeContainer = "t_s7 d_bz d_bH d_bP";
export var badge = "t_s8";
export var padding = "t_s9 d_c7";
export var end = "t_tb d_bH";
export var linkWrapper = "t_tc d_dB";
export var link = "t_mC d_dB";
export var colWrapper = "t_td d_cx";
export var consent = "t_f d_f d_bC d_bP";
export var disclaimer = "t_tf d_bz d_bJ";
export var media = "t_tg d_bx d_dy";
export var itemRight = "t_th";
export var itemLeft = "t_tj";
export var itemCenter = "t_tk";
export var exceptionWeight = "t_tl q_qT";